import { render, staticRenderFns } from "./PaymentMultiBanco.vue?vue&type=template&id=77e9529b&scoped=true"
import script from "./PaymentMultiBanco.vue?vue&type=script&lang=js"
export * from "./PaymentMultiBanco.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77e9529b",
  null
  
)

export default component.exports