<template>
  <div>
  <!-- container com tabela de comparacao e caixa de email -->
  <StepsHeader titulo='Quase lá' step='3' />
    <div class="container mt-5">
      <div class="row justify-content-md-center d-flex">

      <!-- inicio coluna da direita  -->
        <!-- change order on sx and sm: https://stackoverflow.com/a/52301640/3650719 -->
        <div class="col-md-8 col-lg-6 order-2 order-md-1 order-lg-1 order-xl-1">
          <div v-if="error == true" class="error">
            <span><strong>Verifique os itens obrigatórios em vermelho</strong></span>
          </div>
          <!-- inicio dados do cartao -->
            <div class="container">
              <img class="img-fluid" src="@/assets/logo-easypay-960.jpg" style="width: 400px;">
              <h4 class="first-line-table">Escolha uma das opções abaixo:</h4>
                <br>
                  <button class="border border-primary rounded" @click.prevent="redirectToEasyPayCreditCard()" style="height: 95px; width:100%; padding: 8px;"><img class="img-fluid pt-1" src="@/assets/easypay-visa-master.png" style="width: auto; height: 100%"></button>
                <br>
              <br>
                <button class="border border-primary rounded" @click.prevent="getEasyPayMultiBanco()" style="height: 95px; width:100%; padding: 8px;"><img class="img-fluid" src="@/assets/easypay-mb.png" style="width: auto; height: 100%"></button>
              <br><br>
              <div class="crop">
                <button class="border border-primary rounded" @click.prevent="getEasyPayMbway_showTel()" style="height: 95px; width:100%; padding: 8px;"><img class="img-fluid rounded" src="@/assets/easypay-mb-way.png" style="width: auto; height: 100%"></button>
                <div v-if="this.mb_way == true" class="mt-3">
                  <label for="">Número telémovel:</label>
                  <input type="text" v-model="mbway_tel" class="form-control" placeholder="" maxlength="9" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                  <div v-if="error == true" class="error">
                    <span>Telemóvel inválido</span>
                  </div>
                  <button class="btn btn-primary btn-orange my-4" @click.prevent="getEasyPayMbway()">Finalizar</button>
                </div>
              </div>
            </div>
            <br>
          <!-- fim dados dos cartao -->

        </div>
      <!-- fim coluna da direita  -->

      <!-- caixa de resumo -->
        <div class="col-md-7 col-lg-6 col-xl-5 col-sm-7 order-1 mb-4">
          <div class="card background-gray">
            <div class="card-body">
              <table class="table mt-2">
                <thead>
                  <tr>
                    <th colspan="2" class="border-0 title-detalhes">Resumo das cotações</th>
                  </tr>
                </thead>
              </table>
              <div class="card-text font-weight-bolder ml-2" style="font-size: 10px;">
                PRÉMIO FINAL
              </div>
              <div class="card-text font-weight-bolder ml-2 mb-4" style="font-size: 36px;">
                <span >{{ this.chargePrice }} €</span>
              </div>
              <div
                v-if="promotional"
                class="card-text ml-2"
                style="font-size: 12px"
              >
                Código promocional ativo: <b>{{promotional.code}}</b><br>
                Desconto: <b>{{promotional.discount}} %</b>
              </div>

              <table class="table mt-2">
                <thead>
                  <tr>
                    <th colspan="2" class="title-detalhes">Resumo da viagem</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">Destino</td>
                    <td class=""><strong>{{ paises }}</strong></td>
                  </tr>
                  <tr>
                    <td class="">Data de início</td>
                    <td class=""><strong>{{insuranceInitialDate | formatDate }}</strong></td>
                  </tr>
                  <tr>
                    <td class="">Data de fim</td>
                    <td class=""><strong>{{insuranceFinalDate | formatDate }}</strong></td>
                  </tr>
                  <tr>
                    <td class="">Número de pessoas</td>
                    <td class=""><strong>{{insurancePeopleQuantity}}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      <!-- fim caixa resumo -->
      </div>
    </div>
  <!-- fim do container -->
  </div>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout.vue';
import EasyPayService from '../services/EasyPayService';
import StepsHeader from '@/components/StepsHeader.vue';
import moment from 'moment'

export default {
  name: 'Step4',
  components: {
    StepsHeader,
  },
  created() {
    this.$emit('update:layout', PublicLayout)
  },
  data() {
    return {
      url: '',
      mbway_tel: '',
      mb_way: false,
      error: false
    }
  },
  computed: {
    chargePrice () {
      if (this.insurancePlan == 'MOVE') {
        return this.moveFinalPrice
      }else{
        return this.movePlusFinalPrice
      }

    },
    moveFinalPrice() {
      let price = this.insurancePrice.totalValor
      price += parseFloat((this.insuranceCivilResponsability === true) ? this.insurancePrice.totalApRc : 0);
      price += parseFloat((this.insuranceCovid === true) ? this.insurancePrice.totalCovid : 0);
      return price.toFixed(2)
    },
    movePlusFinalPrice() {
      let price = this.insurancePrice.totalValorVip
      price += parseFloat((this.insuranceCivilResponsability === true) ? this.insurancePrice.totalApRcVip : 0);
      price += parseFloat((this.insuranceCovid === true) ? this.insurancePrice.totalCovidVip : 0);
      return price.toFixed(2)
    },
    insurancePersons:{
      get () {
        return this.$store.state.insurance.person
      },
    },
    insuranceId: {
      get () {
        return this.$store.state.insurance.id
      },
    },
    insuranceCountry: {
      get () {
        return this.$store.state.insurance.country
      }
    },
    paises() {
      return (this.$store.state.insurance.country).join(', ');
    },
    insuranceInitialDate: {
      get () {
        return this.$store.state.insurance.initialDate
      }
    },
    insuranceFinalDate: {
      get () {
        return this.$store.state.insurance.finalDate
      }
    },
    insurancePeopleQuantity: {
      get () {
        return this.$store.state.insurance.peopleQuantity
      }
    },
    insurancePlan: {
      get () {
        return this.$store.state.insurance.plan
      }
    },
    insurancePrice: {
      get () {
        return this.$store.state.insurance.price
      }
    },
    insuranceCivilResponsability: {
      get () {
        return this.$store.state.insurance.civilResponsability
      }
    },
    insurancePersonalAccidents: {
      get () {
        return this.$store.state.insurance.personalAccidents
      }
    },
    insuranceCovid: {
      get () {
        return this.$store.state.insurance.covid
      }
    },
    insurancePersonName: {
      get () {
        return this.$store.state.insurance.person.name
      },
      set (value) {
        this.$store.commit('setInsurancePersonName', {insurancePersonName: value})
      }
    },
    insurancePersonSurname: {
      get () {
        return this.$store.state.insurance.person.surname
      },
      set (value) {
        this.$store.commit('setInsurancePersonSurname', {insurancePersonSurname: value})
      }
    },
    insuranceEmail: {
      get () {
        return this.$store.state.insurance.email
      },
      set (value) {
        this.$store.commit('setInsuranceEmail', {email: value})
      }
    },
    insurancePersonBirthday: {
      get () {
        return this.$store.state.insurance.person.birthday
      },
      set (value) {
        this.$store.commit('setInsurancePersonBirthday', {insurancePersonBirthday: value})
      }
    },
    insurancePersonMobileNumber: {
      get () {
        return this.$store.state.insurance.person.mobileNumber
      },
      set (value) {
        this.$store.commit('setInsurancePersonMobileNumber', {insurancePersonMobileNumber: value})
      }
    },
    insuranceAddressLine1: {
      get () {
        return this.$store.state.insurance.address.line1
      },
      set (value) {
        this.$store.commit('setInsuranceAddressLine1', {insuranceAddressLine1: value})
      }
    },
    insuranceAddressLine2: {
      get () {
        return this.$store.state.insurance.address.line2
      },
      set (value) {
        this.$store.commit('setInsuranceAddressLine2', {insuranceAddressLine2: value})
      }
    },
    insuranceAddressCity: {
      get () {
        return this.$store.state.insurance.address.city
      },
      set (value) {
        this.$store.commit('setInsuranceAddressCity', {insuranceAddressCity: value})
      }
    },
    insuranceAddressLocation: {
      get () {
        return this.$store.state.insurance.address.location
      },
      set (value) {
        this.$store.commit('setInsuranceAddressLocation', {insuranceAddressLocation: value})
      }
    },
    insuranceAddressPostalCode: {
      get () {
        return this.$store.state.insurance.address.postalCode
      },
      set (value) {
        this.$store.commit('setInsuranceAddressPostalCode', {insuranceAddressPostalCode: value})
      }
    },
    insuranceAddressCountryOfAddress: {
      get () {
        return this.$store.state.insurance.address.countryOfAddress
      },
      set (value) {
        this.$store.commit('setInsuranceAddressCountryOfAddress', {insuranceAddressCountryOfAddress: value})
      }
    },
    insuranceTerms: {
      get () {
        return this.$store.state.insurance.terms
      },
      set (value) {
        this.$store.commit('setInsuranceTerm', {insuranceTerms: value})
      }
    },
    easyPayUrl: {
      get () {
        return this.$store.state.insurance.easyPayUrl
      },
      set (value) {
        this.$store.commit('setEasyPayUrl', {easyPayUrl: value})
      }
    },
    easyPayEntity: {
      get () {
        return this.$store.state.insurance.easyPayEntity
      },
      set (value) {
        this.$store.commit('setEasyPayEntity', {easyPayEntity: value})
      }
    },
    easyPayReference: {
      get () {
        return this.$store.state.insurance.easyPayReference
      },
      set (value) {
        this.$store.commit('setEasyPayReference', {easyPayReference: value})
      }
    },
    easyPayPrice: {
      get () {
        return this.$store.state.insurance.easyPayPrice
      },
      set (value) {
        this.$store.commit('setEasyPayPrice', {easyPayPrice: value})
      }
    },
    easyPayExpirationTime: {
      get () {
        return this.$store.state.insurance.easyPayExpirationTime
      },
      set (value) {
        this.$store.commit('setEasyPayExpirationTime', {easyPayExpirationTime: value})
      }
    },
    insuranceOrderCode: {
      get () {
        return this.$store.state.insurance.orderCode
      },
      set (value) {
        this.$store.commit('setInsuranceOrderCode', {insuranceOrderCode: value})
      }
    },
    promotional: {
      get() {
        return this.$store.state.promotional;
      },
      set(promotional) {
        this.$store.commit("setPromotional", promotional);
      },
    }
  },
  mounted() {
    if( 
      isNaN(this.insurancePrice.totalValor)    || 
      isNaN(this.insurancePrice.totalValorVip) ||
      !this.insuranceEmail
    ){
      this.$router.push({ name: "/"});
      window.location.reload()
    }
  },
  methods: {
    mb_way_input(val) {
      this.mb_way = val
    },
    error_status(val) {
      this.error = val
    },    
    redirectToEasyPayCreditCard() {
      this.mb_way_input(false)
      this.error_status(false)
      var data = {
        key: this.insuranceOrderCode,
        value: this.chargePrice,
        method: 'cc',
        // customer: {
        //   name: "Pedro Martins Schmitt",
        //   email: "pedromartins.bsb@gmail.com",
        //   phone: "919876543",
        //   phone_indicative: "+351",
        //   fiscal_number: "PT301748446",
        // },
      };
      EasyPayService.createVisa(data)
        .then(response => {
          // this.easyPayUrl = response.data.url;
          this.$store.commit('setEasyPayUrl', {easyPayUrl: response.data.url})
          window.location = this.easyPayUrl;
        })
        .catch(e => {
          alert(e);
        });
    },
    getEasyPayMultiBanco() {
      this.mb_way_input(false)
      this.error_status(false)
      //expiration_time: moment().tz('Europe/London').add(30, 'm').format('YYYY-MM-DD HH:mm'),
      const moment = require('moment-timezone');
      var data = {
        key: this.insuranceOrderCode,
        value: this.chargePrice,
        method: 'mb',
        expiration_time: moment.tz('Europe/Lisbon').add(30, 'm').format('YYYY-MM-DD HH:mm'),
        // customer: {
        //   name: "Pedro Martins Schmitt",
        //   email: "pedromartins.bsb@gmail.com",
        //   phone: "919876543",
        //   phone_indicative: "+351",
        //   fiscal_number: "PT301748446",
        // },
      };

      EasyPayService.createMultiBanco(data)
        .then(response => {
          // this.easyPayUrl = response.data.url;
          this.$store.commit('setEasyPayEntity', {easyPayEntity: response.data.entity})
          this.$store.commit('setEasyPayReference', {easyPayReference: response.data.reference})
          this.$store.commit('setEasyPayPrice', {easyPayPrice: response.data.value})
          this.$store.commit('setEasyPayExpirationTime', {easyPayExpirationTime: response.data.expirationTime})
          this.$router.push("/pagamento-multibanco")
          // window.location = this.easyPayUrl;
        })
        .catch(e => {
          alert(e);
        });
    },
    getEasyPayMbway_showTel(){
      this.mb_way_input(true)
    },

    getEasyPayMbway() {
      this.mbway_button_show = false
      var tel = this.mbway_tel
      var regexp = /^[0-9]{9}?$/;
      if (regexp.test(tel))
      {
        this.error_status(false)

        var data = {
          key: this.insuranceOrderCode,
          value: this.chargePrice,
          method: 'mbw',
          customer: {
            // name: "Pedro Martins Schmitt",
            email: this.insuranceEmail,
            phone: tel,
            phone_indicative: "+351",
            // fiscal_number: "PT301748446",
          },
        };
        
        EasyPayService.createMbway(data)
          .then(response => {
            // this.easyPayUrl = response.data.url;
            if (response.data.message== "MBWay criado com sucesso") {
              this.$store.commit('setEasyPayExpirationTime', {easyPayExpirationTime: response.data.expirationTime})
            }else{
              this.error_status(true)
            }
            this.$router.push("/pagamento-mbway")
          })
          .catch(e => {
            alert(e);
          });
      }
      else
      {
        this.error_status(true)
        return
      }
    },
  }
}
</script>
<style scoped>

  label{
    font-weight: 700;
  }
  .btn-orange{
    background-color:#ff8200;
    border: 0;
  }

  .error {
    color: red;
  }
</style>